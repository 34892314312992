import React from 'react';
import { ReactComponent as IconProfile2 } from '../../assets/icons/icon-profile-2.svg';
import './styles.less';

const UserHeaderInfo = ({ name }: { name?: string }) => {
  return (
    <div className="user-header-info-v2">
      <div className="icon-container">
        <IconProfile2 />
      </div>
      <span className="user-name">Dr.&nbsp;{name}</span>
    </div>
  );
};

export default UserHeaderInfo;
