import React from 'react';
import { IListItem } from './types';
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg';
import Formatter from '../../utils/formatter';

import './styles.less';

interface ListItemProps {
  item: IListItem;
  prepareReferToText(referTo: IListItem['referTo']): string;
  handleDeleteItem(referralId: number): Promise<void>;
  handleSelect(item: IListItem): void;
  disabled?: boolean;
}

export const ListItem = ({
  item,
  prepareReferToText,
  handleDeleteItem,
  handleSelect,
  disabled = false,
}: ListItemProps) => {
  return (
    <div className="list-item" data-id={item.id}>
      <div
        className={`details${disabled ? ' disabled' : ''}`}
        onClick={() => !disabled && handleSelect(item)}
      >
        <div className="title">
          {item?.specialty ?? Formatter.toSentenceCase(item?.referTo?.type)}
        </div>
        <div className="subtitle">
          <span>Refer to: {prepareReferToText(item.referTo)}</span>
        </div>
      </div>
      <div className="remove-btn-wrapper">
        <IconTrash
          className={`trash-icon${disabled ? ' disabled' : ''}`}
          onClick={() => !disabled && handleDeleteItem(item.id)}
        />
      </div>
    </div>
  );
};
