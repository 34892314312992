import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@getvim/atomic-ui';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import { enableSaveApptAsPdfButton } from '../../utils/featureFlagClient';

import { AppointmentType, TaxonomyType } from '../../types';
import AssessmentCPT from './Assessment-cpt';
import AssessmentICD from './Assessment-icd';
import Subjective from './Subjective';
import Objective from './Objective';
import PatientInstructions from './PatientInstructions';
import Referrals from './Referrals';
import Orders from './Orders';
import DownloadApppointmentButton from '../download-appointment-button';
import { Switch } from '../switch';
import useApi from '../../hooks/useApi';
import { Loader } from '@getvim/atomic-ui';
import { omit } from 'lodash-es';
import dayjs from 'dayjs';

import './styles.less';

const TABS = [
  { title: 'Subjective', id: 1, namespace: 'subjective' },
  { title: 'Objective', id: 2, namespace: 'objective' },
  { title: 'Assessment', id: 3, namespace: 'assessment' },
  { title: 'Plan', id: 4, namespace: 'plans' },
  { title: 'Billing Information', id: 5, namespace: 'billing' },
  { title: 'Patient Instructions', id: 8, namespace: 'patient-instructions' },
  { title: 'Referrals', id: 6, namespace: 'referrals' },
  { title: 'Orders', id: 7, namespace: 'orders' },
];

interface AppointmentDetailsNavBarProps {
  patient: any;
  onAppointmentUpdate: () => void;
  selectedAppointment: AppointmentType;
  onCloseAppointment: () => void;
}

const AppointmentDetailsNavBar = ({
  patient,
  onAppointmentUpdate,
  selectedAppointment,
  onCloseAppointment,
}: AppointmentDetailsNavBarProps) => {
  const { id, startDate, isLocked, externalId, npi } = selectedAppointment;

  const api = useApi();

  const [enableSaveApptAsPdfBtn] = useFeatureFlag({
    flagName: enableSaveApptAsPdfButton,
    defaultValue: false,
    team: Team.Interfaces,
  });

  const tabs = document.querySelectorAll('.tab');
  const tabSections = document.querySelectorAll('.tab-section');

  const [appointmentMetadata, setAppointmentMetadata] = useState<{ [key: string]: string }>({});
  const [isApptLocked, setIsApptLocked] = useState(isLocked);
  const [isLoading, setIsLoading] = useState(true);

  const [appointmentDetails, setAppointmentDetails] =
    useState<AppointmentType>(selectedAppointment);

  useEffect(() => {
    getCurrentAppointmentDetails();
  }, [selectedAppointment]);

  const getCurrentAppointmentDetails = async () => {
    setIsLoading(true);
    const appointment = await api.getAppointment({ id: selectedAppointment.id });
    setAppointmentDetails(appointment);
    setIsLoading(false);
  };

  const getAppointmentAssessment = async () => {
    setIsLoading(true);
    const appointment = await api.getAppointment({ id: selectedAppointment.id });
    setAppointmentDetails((prev) => ({ ...prev, assessment: appointment.assessment }));
    setIsLoading(false);
  };

  const handleMetadataUpdate = (newValue: { [key: string]: string }) => {
    setAppointmentMetadata((prev) => {
      return {
        ...prev,
        ...newValue,
      };
    });
  };

  const saveMetadata = async () => {
    setIsLoading(true);
    await Promise.all(
      Object.entries(appointmentMetadata).map(([key, value]) => {
        return api.upsertMetadata(id, { key, value });
      }),
    ).finally(() => setIsLoading(false));
    onAppointmentUpdate();
  };

  const changeApptIsLocked = async (isLocked: boolean) => {
    setIsLoading(true);
    setIsApptLocked(isLocked);
    const updatedAppt = {
      ...omit(selectedAppointment, ['metadata', 'assessment']),
      isLocked,
    };
    await api.updateAppointment(updatedAppt);
    onAppointmentUpdate();
    setIsLoading(false);
  };

  const updateIsLoading = (value: boolean) => setIsLoading(value);

  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Array.from(tabSections).indexOf(entry.target);
          tabs.forEach((tab) => {
            tab.classList.remove('active');
          });
          tabs[index].classList.add('active');
        }
      });
    },
    {
      threshold: 0.8, // with more than 0.8 tabs are not correctly switched when zoomed-in
    },
  );

  tabSections.forEach((sections) => {
    observer.observe(sections);
  });

  return (
    <div
      className="appointment-nav-bar-container"
      data-id="appointment-nav-bar-container"
      data-appt-id={id}
      data-appt-external-id={externalId}
      data-appt-npi={npi}
      data-appt-date={startDate}
      data-appt-is-locked={isApptLocked}
    >
      {isLoading && <Loader type="dots" size="small" label="Loading" />}

      <div className="appointment-title-container">
        <div className="appointment-title">
          <span className="appointment-title__back-encounters" onClick={onCloseAppointment}>
            Encounters
          </span>
          <span className="appointment-title__date">{` / ${dayjs(startDate).format(
            'MMM DD, YYYY',
          )}`}</span>
        </div>
        {enableSaveApptAsPdfBtn && (
          <DownloadApppointmentButton
            appointmentId={id}
            patientId={patient.id}
            isLoading={isLoading}
          />
        )}
      </div>

      <div id="nav-bar-container">
        <ul id="tabs" className="nav-bar-tabs">
          {TABS.map(({ title, id, namespace }) => {
            return (
              <li key={title} className="tab">
                <a href={`#${namespace}`}>{title}</a>
              </li>
            );
          })}
        </ul>
        <Switch
          checked={isApptLocked}
          disabled={isLoading}
          label={isApptLocked ? 'Unlock' : 'Lock'}
          onCheckedChange={async (isLocked) => await changeApptIsLocked(isLocked)}
        />
        <div className={`body-section__content${isApptLocked || isLoading ? ' disabled' : ''}`}>
          <div className="tab-section">
            <span id="subjective" className="anchoroffset"></span>
            <Subjective
              selectedAppointment={appointmentDetails}
              loading={isLoading}
              onChange={(updatedMetadata) => {
                handleMetadataUpdate(updatedMetadata);
              }}
              isApptLocked={isApptLocked}
            />
          </div>
          <div className="tab-section">
            <span id="objective" className="anchoroffset"></span>
            <Objective
              selectedAppointment={appointmentDetails}
              loading={isLoading}
              onChange={(updatedMetadata) => {
                handleMetadataUpdate(updatedMetadata);
              }}
              isApptLocked={isApptLocked}
            />
          </div>
          <div className="tab-section">
            <span id="assessment" className="anchoroffset"></span>
            <AssessmentICD
              patient={patient}
              type={TaxonomyType.ICD}
              selectedAppointment={appointmentDetails}
              onChange={(updatedMetadata) => {
                handleMetadataUpdate(updatedMetadata);
              }}
              onUpdateIcdList={getAppointmentAssessment}
              isApptLocked={isApptLocked}
              handleLoading={updateIsLoading}
            />
          </div>
          <div className="tab-section">
            <span id="plans" className="anchoroffset"></span>
            <AssessmentCPT
              sectionTitle="Plan"
              type={TaxonomyType.CPT}
              selectedAppointment={appointmentDetails}
              onChange={(updatedMetadata) => {
                handleMetadataUpdate(updatedMetadata);
              }}
              onUpdateCptList={getAppointmentAssessment}
              isApptLocked={isApptLocked}
              handleLoading={updateIsLoading}
            />
          </div>
          <div className="tab-section">
            <span id="billing" className="anchoroffset"></span>
            <AssessmentCPT
              sectionTitle="Billing Info"
              type={TaxonomyType.CPT}
              selectedAppointment={appointmentDetails}
              onUpdateCptList={getAppointmentAssessment}
              isApptLocked={isApptLocked}
              handleLoading={updateIsLoading}
            />
          </div>
          <div className="tab-section">
            <span id="patient-instructions" className="anchoroffset"></span>
            <PatientInstructions
              loading={isLoading}
              onChange={(updatedMetadata) => {
                handleMetadataUpdate(updatedMetadata);
              }}
              selectedAppointment={appointmentDetails}
              isApptLocked={isApptLocked}
            />
          </div>
          <div className="tab-section">
            <span id="referrals" className="anchoroffset"></span>
            <Referrals
              patient={patient}
              appointmentId={id}
              api={api}
              isApptLocked={isApptLocked}
              handleLoading={updateIsLoading}
            />
          </div>
          <div className="tab-section">
            <span id="orders" className="anchoroffset"></span>
            <Orders
              patient={patient}
              appointmentId={id}
              api={api}
              isApptLocked={isApptLocked}
              handleLoading={updateIsLoading}
            />
          </div>
        </div>
        <div className="dialog-footer-v2">
          <Row className="footer-btns">
            <Col xs={6}>
              <Button
                bsPrefix="btn btn-secondary-v2"
                className="cancel-btn"
                onClick={onCloseAppointment}
                disabled={isLoading}
                buttonType="small"
                bgColor="themedOutline"
                width="small"
              >
                Cancel
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                type="submit"
                bsPrefix="btn btn-primary-v2"
                className="apply-btn"
                disabled={isLoading || isApptLocked}
                onClick={() => saveMetadata()}
                buttonType="small"
                width="small"
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetailsNavBar;
