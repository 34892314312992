import React, { useEffect, useState } from 'react';
import { Toast } from '@getvim/atomic-ui';
import { Table } from 'react-bootstrap';
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg';

import { isNil, get } from 'lodash-es';
import { Loader } from '@getvim/atomic-ui';
import { NewItemButton } from '../referrals-orders-lists/newItemButton';
import { ItemModal } from '../referral-order-modal/itemModal';
import { IListItem, IPatientProps } from '../referrals-orders-lists/types';
import { ListType } from '../../types';

import './styles.less';

interface ReferralsProps {
  title: string;
  columnTitle: string;
  columnField: string;
  getReferToData: (item: any) => string;
  modalTitle: string;
  patient: IPatientProps;
  appointmentId: number;
  isApptLocked: boolean;
  newItemTitle: string;
  getItemsByApi: ({ patient, appointmentId }: { patient: any; appointmentId: number }) => any;
  handleItemCreate: (body: any) => any;
  handleItemDelete: (id: number) => any;
  handleItemUpdate: (referralId: number, body: any) => any;
  listType: ListType;
}

const { ToastTypes, createToast } = Toast;

export const ReferralOrderTable = ({
  title,
  columnTitle,
  columnField,
  getReferToData,
  modalTitle,
  patient,
  appointmentId,
  isApptLocked,
  listType,
  newItemTitle,
  getItemsByApi,
  handleItemCreate,
  handleItemDelete,
  handleItemUpdate,
}: ReferralsProps) => {
  const [items, setItems] = useState<any>();

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const { data } = await getItemsByApi({ patient, appointmentId });
    setItems(data);
  };

  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IListItem | null>(null);

  const isNewItem = !selectedItem;

  const openModal = () => {
    setItemModalOpen(true);
  };

  const itemModalToggle = () => {
    setItemModalOpen(!itemModalOpen);
  };

  const errorToastWrapper = async (cb: any) => {
    try {
      await cb();
      setItemModalOpen(false);
    } catch (err: any) {
      createToast({
        title: `Oops, error!`,
        message: err.error?.message[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });

      throw err;
    }
  };

  const handleItemRowSelected = (item) => {
    if (isApptLocked) return;

    setSelectedItem(item);
    openModal();
  };

  return (
    <>
      {isNil(items) && <Loader type="dots" size="small" label="Loading" />}

      {!isNil(items) && (
        <div className="item-notes-wrapper-v2">
          <div className="title-container">
            <div className="title">{title}</div>
            <div className="add-btn-wrapper">
              <NewItemButton
                handleClick={() => setItemModalOpen(true)}
                newItemTitle="Add"
                disabled={isApptLocked}
              />
            </div>
          </div>
          <div className="items-container">
            <div className="table-wrapper-v2">
              <Table borderless hover>
                <thead>
                  <tr>
                    <th className="description-column">{columnTitle}</th>
                    <th>Refer to</th>
                    <th className="drop-icon-column" />
                  </tr>
                </thead>
                <tbody>
                  {items.map((item: any) => {
                    return (
                      <>
                        <tr
                          key={item.id}
                          className={`main-row-v2${isApptLocked ? ' disabled' : ''}`}
                        >
                          <td
                            className="description-column"
                            title={get(item, columnField)}
                            onClick={() => handleItemRowSelected(item)}
                          >
                            {get(item, columnField)}
                          </td>
                          <td
                            className=""
                            title={getReferToData(item)}
                            onClick={() => handleItemRowSelected(item)}
                          >
                            {getReferToData(item)}
                          </td>
                          <td>
                            <IconTrash
                              className={`trash-icon-v2${isApptLocked ? ' disabled' : ''}`}
                              onClick={async () => {
                                if (isApptLocked) return;

                                await handleItemDelete(item?.id);
                                await getItems();
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
          {itemModalOpen && (
            <ItemModal
              patient={patient}
              appointmentId={appointmentId}
              modalTitle={modalTitle}
              newItemTitle={newItemTitle}
              isNewItem={isNewItem}
              isOpen={itemModalOpen}
              onUpdate={async (id, body) => {
                const cb = async () => {
                  await handleItemUpdate(id, body);
                  await getItems();
                  setSelectedItem(null);
                };

                await errorToastWrapper(cb);
              }}
              onSave={async (body) => {
                const cb = async () => {
                  await handleItemCreate(body);
                  await getItems();
                  setSelectedItem(null);
                };

                await errorToastWrapper(cb);
              }}
              onClose={() => {
                itemModalToggle();
                setSelectedItem(null);
              }}
              listType={listType}
              referral={selectedItem}
            />
          )}
        </div>
      )}
    </>
  );
};
