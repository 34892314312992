import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Button, Col, Row } from 'react-bootstrap';
import * as uuid from 'uuid';
import { useForm } from 'react-form';
import { BkmdModal, ModalHeader, Toast } from '@getvim/atomic-ui';
import { isEmpty, isString } from 'lodash-es';
import Loader from '../loader';

import Field from '../form-field';
import { fields } from './appointmentModalDef';
import useApi from '../../hooks/useApi';
import { useUserData } from '../../hooks/useUserData';

import './index.less';
import 'react-datepicker/dist/react-datepicker.css';

const { ToastTypes, createToast } = Toast;

const AppointmentModal = (props: {
  member?: any;
  closeModal?: any;
  updateSlots?: any;
  modalVisibility?: any;
  providers: Array<any>;
  patients: Array<any>;
  providerSlots?: Array<{
    startTime: string;
    endTime: string;
  }>;
  defaultSelectedProvider?: any;
  getPatients?: any;
  getProviders?: any;
}) => {
  const {
    closeModal,
    modalVisibility,
    providers,
    patients,
    providerSlots = [],
    defaultSelectedProvider,
    updateSlots,
    getPatients,
    getProviders,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [slots, setSlots] = useState<any>(providerSlots);

  const getNearest30MinuteSlot = () => {
    const momentTime = moment(new Date(), 'HH:mm').seconds(0).milliseconds(0);
    const minutes = momentTime.minutes();

    if (minutes < 30) {
      momentTime.minutes(30);
    } else {
      momentTime.add(1, 'hours').minutes(0);
    }

    const verifyOcuppiedSlot = () => {
      return Boolean(
        slots.find(({ startTime }) => {
          return momentTime.toDate().toISOString() === new Date(startTime).toISOString();
        }),
      );
    };

    while (verifyOcuppiedSlot()) {
      momentTime.add(30, 'minutes');
    }

    setFormData((prev) => {
      return {
        ...prev,
        startTime: momentTime.toDate(),
      };
    });
  };

  const initialData = {
    reasonForVisit: undefined,
    startTime: undefined,
    provider: undefined,
    patient: undefined,
    externalId: undefined,
  };

  const api = useApi();
  const user = useUserData();

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>(
    !isEmpty(modalVisibility)
      ? modalVisibility
      : {
          ...initialData,
          provider: providers
            .filter((provider: { npi: string }) => provider.npi === defaultSelectedProvider)
            .find((provider: { firstName: any; lastName: any }) => ({
              ...provider,
              providerName: `${provider?.firstName} ${provider?.lastName}`,
            })),
          patient: patients[0],
        },
  );

  useEffect(() => {
    setSlots(providerSlots);
    if (isEmpty(modalVisibility)) {
      getNearest30MinuteSlot();
    }
  }, [providerSlots]);

  const getDefaultSelectedProvider = () => {
    return !isEmpty(modalVisibility)
      ? [
          {
            ...formData?.provider,
            providerName: `${formData?.provider?.firstName} ${formData?.provider?.lastName}`,
          },
        ]
      : [
          ...providers
            .filter((provider: { npi: string }) => provider.npi === defaultSelectedProvider)
            .map((provider: { firstName: any; lastName: any }) => ({
              ...provider,
              providerName: `${provider?.firstName} ${provider?.lastName}`,
            })),
        ];
  };

  const getDefaultSelectedPatient = () => {
    return !isEmpty(modalVisibility)
      ? [
          {
            ...formData?.patient,
            patientName: `${formData?.patient?.firstName} ${formData?.patient?.lastName}`,
          },
        ]
      : [{ ...patients[0], patientName: `${patients[0]?.firstName} ${patients[0]?.lastName}` }];
  };

  const defaultValues = useMemo(
    () => ({
      ...formData,
      provider: getDefaultSelectedProvider()[0],
      patient: getDefaultSelectedPatient()[0],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.startTime],
  );

  const formProps = useForm({
    defaultValues,
    onSubmit: async () => {
      !!modalVisibility && modalVisibility?.id
        ? await bookAppointment(true)
        : await bookAppointment();
    },
  });

  const {
    Form,
    setFieldValue,
    meta: { canSubmit },
  } = formProps;

  const bookAppointment = async (reschedule = false) => {
    const payload = {
      // toISOString adds microseconds that break slot availability check
      startDate: moment(formData.startTime).seconds(0).milliseconds(0).toISOString(),
      endDate: moment(formData.startTime)
        .add(30, 'minutes')
        .seconds(0)
        .milliseconds(0)
        .toISOString(),
      npi: formData.provider.npi,
      reasonForVisit: formData.reasonForVisit?.trim(),
      locationId: providers.find((provider) => provider.npi === formData.provider.npi).clinics[0]
        .location.id,
      providerId: formData.provider.id,
      patientId: formData.patient.id,
      externalId: formData.externalId?.trim(),
    };

    setIsLoading(true);
    try {
      reschedule
        ? await api.updateAppointment({ ...payload, id: modalVisibility.id })
        : await api.bookAppointment(payload);

      updateSlots();
      closeModal();
      setFormData({ ...initialData });
    } catch (err: any) {
      createToast({
        title: 'Error!',
        message: err.message,
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });

      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const excludeBookedSlots = (date: Date) => {
    return slots.find((slot: { startTime: string | number | Date }) => {
      return date.getTime() === new Date(slot.startTime).getTime();
    }) || date.getTime() < new Date().getTime()
      ? 'react-datepicker__time-list-item--disabled'
      : null;
  };

  const getProviderAvailability = async ({ npi, date }: { npi: string; date?: Date }) => {
    const provider = providers.find((provider: { npi: string }) => provider.npi === npi);
    const locationId = provider.clinics[0]?.location?.id;
    if (!locationId) return;

    const slotsDate = date || new Date();
    const providerSlots = await api.getProviderAvailability({
      id: provider.id,
      locationId,
      startDate: moment(slotsDate).startOf('month').toDate(),
      days: moment(slotsDate).daysInMonth(),
    });
    setSlots(providerSlots);
  };

  const updateFormData = ({ field, value }: { field: string; value: any }) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [`${field}`]: value,
    }));
  };

  const modalBody = (
    <div className="body-section">
      <div className="appointment">
        <div className="appointment__select-wrapper">
          <Field
            labelKey="patientName"
            defaultSelected={getDefaultSelectedPatient()}
            renderMenuItemChildren={(name: React.ReactNode) => {
              return <div>{name}</div>;
            }}
            options={patients.map((patient: any) => ({
              ...patient,
              patientName: `${patient.firstName} ${patient.lastName}`,
            }))}
            onChange={(value: any[]) => {
              updateFormData({
                field: 'patient',
                value: value[0],
              });
            }}
            onInputChange={(value: string) => {
              if (getPatients) {
                getPatients(value);
              }
            }}
            id="patient-select"
            submitted={submitted}
            description="* Patient"
            placeholder="Select member"
            disableFilter={formData.patient?.id}
            positionFixed
            {...fields.patient}
          />
        </div>

        <div className="appointment__title-wrapper">
          <Field
            submitted={submitted}
            value={
              isString(formData?.reasonForVisit)
                ? formData?.reasonForVisit
                : initialData.reasonForVisit
            }
            className="appointment__title-wrapper__input"
            inputType
            onChange={(e: any) => {
              return updateFormData({
                field: 'reasonForVisit',
                value: e.target.value?.replace(/^\s*/, ''),
              });
            }}
            label="* Reason For Visit"
            {...fields.reasonForVisit}
          />
        </div>
        <div className="appointment__select-wrapper">
          <Field
            labelKey="providerName"
            selected={getDefaultSelectedProvider()}
            renderMenuItemChildren={(name: React.ReactNode) => {
              return <div>{name}</div>;
            }}
            options={providers
              .map((provider: any) => ({
                ...provider,
                providerName: `${provider.firstName} ${provider.lastName}`,
              }))
              .filter((provider) => provider.clinics.length)}
            onChange={(value: any[]) => {
              const isSelectedSameProvider = value[0]?.id === formData.provider?.id;
              updateFormData({
                field: 'provider',
                value: value[0],
              });
              if (!isEmpty(value) && !isSelectedSameProvider) {
                // Reset appointment date and get selected provider availability
                getProviderAvailability({ npi: value[0].npi });
                updateFormData({
                  field: 'startTime',
                  value: null,
                });
                setFieldValue('startTime', undefined);
              }
            }}
            onInputChange={(value: string) => {
              if (getProviders) {
                getProviders(value);
              }
            }}
            id="provider-select"
            submitted={submitted}
            description="* Provider"
            placeholder="Select provider"
            disableFilter={formData.provider?.id}
            positionFixed
            disabled={!user.isAdmin}
            {...fields.provider}
          />
        </div>

        <div className="appointment__datepicker-wrapper">
          <Field
            submitted={submitted}
            dateType
            value={formData?.startTime}
            onChange={(date: any) => {
              return updateFormData({
                field: 'startTime',
                value: date,
              });
            }}
            selected={formData?.startTime}
            timeClassName={(date: Date) => excludeBookedSlots(date)}
            onMonthChange={(date: any) =>
              getProviderAvailability({ npi: defaultSelectedProvider, date })
            }
            label="* DateTime Range"
            {...fields.startTime}
          />
        </div>
        <div className="appointment__title-wrapper">
          <Field
            submitted={submitted}
            className="appointment__title-wrapper__input"
            inputType
            value={formData.externalId}
            onChange={(e: { target: { value: string } }) => {
              updateFormData({
                field: 'externalId',
                value: e.target.value ? e.target.value?.replace(/^\s*/, '') : undefined,
              });
            }}
            label="External ID"
            {...fields.externalId}
          />
        </div>
      </div>
    </div>
  );

  return (
    <BkmdModal
      isOpen={!!modalVisibility}
      handleClose={closeModal}
      autoFocus={false}
      name="appointment-modal"
      className="appointment-modal fixed-header"
    >
      <div id={`appointment-modal-${uuid.v1()}`}>
        <Form method="post" className="appointment-modal-form">
          <ModalHeader
            onClose={closeModal}
            title={!!modalVisibility && modalVisibility.id ? 'Edit Appointment' : 'Add Appointment'}
          />

          <div className="dialog-body">
            {modalBody}
            {(isLoading || isEmpty(providers)) && <Loader />}
          </div>
          <div className="dialog-footer">
            <Row className="footer-btns">
              <Col xs={6}>
                <Button bsPrefix="btn btn-secondary" className="cancel-btn" onClick={closeModal}>
                  Cancel
                </Button>
              </Col>

              <Col xs={6}>
                <Button
                  type="submit"
                  bsPrefix="btn btn-primary"
                  className="apply-btn"
                  disabled={!canSubmit}
                  onClick={() => setSubmitted(true)}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </BkmdModal>
  );
};
export default AppointmentModal;
