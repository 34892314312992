import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  MutableRefObject,
  forwardRef,
  useRef,
} from 'react';
import classNames from 'classnames';
import DropDownSelect, { SelectItemRenderer } from 'react-dropdown-select';
import { InputStyle, InputStyleWrapper } from '@getvim/atomic-ui';
import debounce from 'debounce';

import './styles.less';

export type SelectItemRendererProps = SelectItemRenderer<any>;

const Select = forwardRef((props: any, ref) => <DropDownSelect {...props} ref={ref} />);
Select.displayName = 'Select';
export type SelectRef = MutableRefObject<{ select: React.RefObject<HTMLInputElement> }>;

export interface SelectInputProps {
  options: any[];
  selectedOption?: any;
  onChange: (event: any) => void;
  className?: string;
  labelField?: string;
  label?: string;
  valueField?: string;
  sortOptions?: boolean;
  noDataLabel?: string;
  withDebounce?: boolean;
  debounceTime?: number;
  noResultsLabel?: string;
}

export default function SelectInput({
  options,
  selectedOption,
  onChange,
  className,
  label,
  labelField = 'label',
  valueField,
  sortOptions = true,
  noResultsLabel = 'No results found',
  withDebounce = true,
  debounceTime = 500,
}: SelectInputProps) {
  const ref = useRef();

  const [active, setActive] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(false);
  const [initialOptions, setInitialOptions] = useState(options);
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  useEffect(() => {
    setInitialOptions(options);
    setFilteredOptions(options);
  }, [options]);

  const filterOptionsByString = useCallback(
    (searchString: string) => {
      if (!searchString) {
        setFilteredOptions(initialOptions);
      } else {
        const filtered = initialOptions.filter((option) =>
          option.name.toLowerCase().includes(searchString.toLowerCase()),
        );
        setFilteredOptions(filtered);
      }
    },
    [initialOptions],
  );

  const searchMethod = debounce(
    ({ state, methods }) => {
      const searchString = methods.safeString(state.search);
      filterOptionsByString(searchString);
    },
    withDebounce ? debounceTime : 0,
  );

  const onSelectedChange = (value, onChangeProp) => {
    setHasValue(value.length);

    onChangeProp(value);
  };

  const sortedOptions = useMemo(
    () =>
      [...filteredOptions].sort((a, b) => {
        if (a[labelField].toLowerCase() < b[labelField].toLowerCase()) {
          return -1;
        }
        if (a[labelField].toLowerCase() > b[labelField].toLowerCase()) {
          return 1;
        }

        return 0;
      }),
    [filteredOptions, labelField],
  );

  return (
    <InputStyleWrapper
      className="select-dropdown-wrapper"
      inputStyle={InputStyle.medium}
      onClick="this.select()"
    >
      <div className="react-dropdown-select-wrap">
        <Select
          placeholder=""
          ref={ref}
          searchable
          name={valueField}
          options={sortOptions ? sortedOptions : filteredOptions}
          values={[selectedOption]}
          onChange={(value) => onSelectedChange(value, onChange)}
          className={className}
          valueField={valueField}
          labelField={labelField}
          searchBy={labelField}
          searchFn={(props) => {
            //@ts-ignore
            if (ref.current.state.values.length) {
              //@ts-ignore
              ref.current.state.values = [];
            }
            searchMethod(props);
          }}
          onDropdownOpen={() => {
            setActive(true);
          }}
          onDropdownClose={() => {
            setActive(false);
            //@ts-ignore
            if (!ref.current?.state.values?.length) {
              //@ts-ignore
              ref.current.state.values[0] = selectedOption;
              setFilteredOptions(initialOptions);
            }
          }}
          noDataLabel={noResultsLabel}
          keepSelectedInList
          closeOnSelect={false}
          dropdownGap={0}
          withDebounce
        />
        {label && (
          <label
            className={classNames('select-dropdown-label', {
              'input-active': active || hasValue || selectedOption,
            })}
          >
            {label}
          </label>
        )}
        <div className={classNames('select-chevron-icon', { active })}>
          <i className="icon-chevron-down" />
        </div>
      </div>
    </InputStyleWrapper>
  );
}
