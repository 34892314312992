/* eslint-disable no-underscore-dangle */
import React, { createContext, useEffect, useState } from 'react';
import { useUserData } from './hooks/useUserData';
import { updateAppOverride } from './pages-v2/vim-settings';
import { useAuth0 } from '@auth0/auth0-react';
import useClientConfig from './config/useClientConfig';
import { updateSDKLocalStorage } from './pages-v2/vim-settings/sdkLocalStorage';

const appEnv = globalThis.$vim_environment?.APP_ENV ?? 'prod';
const scriptSource = (() => {
  const file = 'index.js';
  const path = `ehr-sdk/${file}`;
  switch (appEnv) {
    case 'local':
      return `https://connect.dev.devim.io/${path}`;
    case 'dev':
    case 'staging': {
      const domain = window.location.origin.includes('getvim.com') ? 'getvim.com' : 'devim.io';
      return `https://connect.${appEnv}.${domain}/${path}`;
    }
    case 'demo':
      return `https://connect.demo.getvim.com/${path}`;
    case 'prod':
    default:
      return `https://connect.getvim.com/${path}`;
  }
})();

const initSdk = (user: any, idToken?: string) => {
  const config = {
    apiKey: 'your-api-key',
  };

  const userInfo = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    clinicId: user.organization.id.toString(),
    ehrUsername: user.email,
    npi: user.npi,
    idToken,
  };

  globalThis.VimConnectSDK.init(config, userInfo);
};

export const EhrSDKContext = createContext<{ isSdkLoaded: boolean }>({ isSdkLoaded: false });

export const SdkWrapper: React.FC = ({ children }) => {
  const [isSdkLoaded, setIsSdkLoaded] = useState(
    document.querySelector(`script[src="${scriptSource}"]`) !== null,
  );
  const user = useUserData();
  const { VIM_OAUTH0_AUDIENCE } = useClientConfig();
  const { getIdTokenClaims, isAuthenticated } = useAuth0();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('appId') && searchParams.has('appVersion')) {
      updateAppOverride({
        appId: searchParams.get('appId') as string,
        version: searchParams.get('appVersion') as string,
      });
    }

    // Enable SDK, VimOS logs
    updateSDKLocalStorage('enableLogs', true);
    localStorage.setItem('VIM_CONSOLE_LOG_LEVEL', 'debug');
  }, []);

  useEffect(() => {
    if (!isSdkLoaded && isAuthenticated && user.email) {
      if (globalThis.$vimConnectScripts) {
        console.log('not running sdk: Vim Connect extension is running');
        return;
      }
      setIsSdkLoaded(true);
      globalThis.vimAsyncInit = async () => {
        try {
          const token = await getIdTokenClaims({
            audience: VIM_OAUTH0_AUDIENCE,
          });

          console.info('Got mock-ehr access token, login with id-token', { token });
          initSdk(user, token.__raw);
        } catch (error) {
          console.error('failed to get access token, login with sso', error);
          initSdk(user);
        }
      };
      const sdkScript = document.createElement('script');
      sdkScript.src = scriptSource;

      sdkScript.addEventListener('error', (error) => {
        console.error('failed to load EHR SDK', error);
      });
      document.head.insertBefore(sdkScript, document.head.firstElementChild);
    }
  }, [user, isSdkLoaded, isAuthenticated, getIdTokenClaims, VIM_OAUTH0_AUDIENCE]);

  return <EhrSDKContext.Provider value={{ isSdkLoaded }}>{children}</EhrSDKContext.Provider>;
};
