import React, { forwardRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import classNames from 'classnames';

import './styles.less';

interface SelectTypeaheadProps {
  description: string;
  labelKey: string;
  filterBy?: string[];
  disableFilter?: boolean;
  className: string;
  options: any[];
  renderMenuItemChildren?(value: string, code: string): any;
  ref?: any;
  error: any;
  submitted?: boolean;
  placeholder?: string;
}

export const SelectTypeahead = forwardRef((props: SelectTypeaheadProps, ref: any) => {
  const {
    description,
    labelKey,
    filterBy,
    disableFilter = false,
    className,
    error,
    submitted,
    placeholder,
  } = props;

  return (
    <div id={labelKey} className={`${className} v2-input`}>
      <div className={`${error && submitted && 'has-error'} select-wrapper`}>
        <div className="select">
          <Typeahead
            {...props}
            ref={ref}
            id="select-input"
            placeholder={placeholder}
            filterBy={disableFilter ? () => true : filterBy || [`${labelKey}`]}
            renderMenuItemChildren={(value) => {
              if (!props.renderMenuItemChildren) return;
              return props.renderMenuItemChildren(value[labelKey], value);
            }}
          />
          <span className="typeahead-select-label">{description}</span>
        </div>
      </div>
    </div>
  );
});
